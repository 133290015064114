import { TypedDocumentNode as DocumentNode } from "@graphql-typed-document-node/core";
import { GraphQLClient } from "graphql-request";
import {
  QueryKey,
  useInfiniteQuery,
  UseInfiniteQueryOptions,
  UseInfiniteQueryResult,
  useMutation,
  UseMutationOptions,
  useQuery,
  useQueryClient,
  UseQueryOptions,
} from "@tanstack/react-query";

import { useProject } from "@/contexts";
import { getPublicConfig } from "@/helpers/getPublicConfig";
import { auth } from "@/services/firebase/firebase";

type UseQueryArgsType<Result, Variables, Data = Result> = {
  queryKey: QueryKey;
  gqlDocument: DocumentNode;
  gqlVariables?: Variables;
  options?: UseQueryOptions<Result, Error, Data>;
};

type UseInfinteQueryArgsType<Result, Variables> = {
  queryKey: QueryKey;
  gqlDocument: DocumentNode;
  options?: UseInfiniteQueryOptions<Result, Error>;
  /**
   * @deprecated we should use variables instead, but in the future, better generate the grapqhl react query hook instead writing by ourself
   * @returns
   */
  getGqlVariables?: (cursor: number) => Variables;
  variables?: Variables;
};

type UseMutationArgsType<Result, Variables> = {
  refetchKey: QueryKey;
  gqlDocument: DocumentNode;
  options?: UseMutationOptions<Result, Error, Variables>;
};

async function getHttpHeader() {
  return {
    authorization: `Bearer ${await auth.currentUser?.getIdToken()}`,
  };
}

const { env } = getPublicConfig();

export const useGqlClient = () => {
  const queryClient = useQueryClient();
  const { project } = useProject();

  const gqlEndpoint = project?.graphqlEndpoint[env.vercelEnv] ?? "";
  const graphQLClient = new GraphQLClient(gqlEndpoint);

  const useGqlQuery = <Result, Variables, Data = Result>({
    queryKey,
    gqlDocument,
    gqlVariables,
    options = {},
  }: UseQueryArgsType<Result, Variables, Data>) => {
    return useQuery(
      queryKey,
      async () => {
        return await graphQLClient.request(
          gqlDocument,
          gqlVariables,
          await getHttpHeader(),
        );
      },
      {
        staleTime: Infinity,
        ...options,
      },
    );
  };

  const useGqlInfiniteQuery = <Result, Variables>({
    queryKey,
    gqlDocument,
    options,
    getGqlVariables,
    variables,
  }: UseInfinteQueryArgsType<Result, Variables>): UseInfiniteQueryResult<
    Result,
    Error
  > => {
    // the old getGqlVariables implementation is problematic, we should use the variables instead
    const useVariables = !getGqlVariables && variables;
    return useInfiniteQuery(
      queryKey,
      async ({ pageParam: cursor }) => {
        return await graphQLClient.request(
          gqlDocument,
          useVariables ? variables : getGqlVariables?.(cursor),
          await getHttpHeader(),
        );
      },
      {
        staleTime: Infinity,
        ...options,
      },
    );
  };

  const useGqlMutation = <Result, Variables>({
    refetchKey,
    gqlDocument,
    options,
  }: UseMutationArgsType<Result, Variables>) => {
    return useMutation(
      async (variables) => {
        return graphQLClient.request(
          gqlDocument,
          variables,
          await getHttpHeader(),
        );
      },
      {
        onSuccess: () => {
          if (Array.isArray(refetchKey) && refetchKey.length) {
            refetchKey.forEach((key) => {
              queryClient.invalidateQueries(key);
            });
          } else {
            queryClient.invalidateQueries(refetchKey);
          }
        },

        ...options,
      },
    );
  };

  return {
    gqlEndpoint,
    /**
     *  @remarks the query key should be [string, variables ]
     */
    useGqlQuery,
    useGqlInfiniteQuery,
    useGqlMutation,
  };
};
