import cookies from "js-cookie";

const TOKEN_NAME = "auth";
const COOKIE_EXPIRY_DAYS = 30;

export const setUserCookie = (user: string | object) => {
  cookies.set(TOKEN_NAME, user as string, {
    // firebase id tokens expire in one hour
    // set cookie expiry to match
    expires: COOKIE_EXPIRY_DAYS,
  });
};

export const removeUserCookie = () => cookies.remove(TOKEN_NAME);
