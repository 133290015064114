import * as Sentry from "@sentry/nextjs";
import { AuthError } from "firebase/auth";
import { ClientError } from "graphql-request";
import { TRPCClientError } from "@trpc/client";
import { useNotificationContext } from "@/contexts/notification";
import {
  expectedMutationErrors,
  getGraphqlErrors,
  getMutationErrorMessage,
} from "./error/graphql";
import {
  AuthErrorCode,
  FirebaseErrorMessages,
  isFirebaseAuthError,
} from "./error/firebase";

export function useErrorHandling() {
  const { openNotification } = useNotificationContext();

  function handleFirebaseAuthError(
    error: AuthError,
    tags?: Record<string, string>,
  ) {
    if (!isFirebaseAuthError(error)) {
      return false;
    }

    const ignoreErrorCodes: string[] = [
      AuthErrorCode.POPUP_CANCELLED,
      AuthErrorCode.POPUP_CLOSED,
    ];

    if (ignoreErrorCodes.includes(error.code)) return;

    let message = "";

    switch (error.code) {
      case AuthErrorCode.POPUP_BLOCKED:
      case AuthErrorCode.ACCOUNT_EXISTS_WITH_DIFF_CREDENTIAL:
        message = `${tags?.login} sign in popup is blocked.`;
        break;
      case AuthErrorCode.UNAUTHORIZED_DOMAIN:
        message = "Sign in is blocked for this link.";
        break;
      default:
        message =
          FirebaseErrorMessages[error.code as AuthErrorCode] ??
          "Unexpected Error. Please try again or contact admin.";
        break;
    }

    openNotification({
      message,
      variant: "danger",
    });

    Sentry.captureException(error, { tags });
    return true;
  }

  function handleGraphqlMutationError(
    error: ClientError,
    tags?: Record<string, string>,
  ) {
    const graphqlErrors = getGraphqlErrors(error);
    if (!graphqlErrors) {
      return false;
    }
    const { message, extensions } = graphqlErrors[0];

    if (!expectedMutationErrors.includes(message)) {
      openNotification({
        message: "Unexpected error occurred",
        variant: "danger",
      });

      return true;
    }

    openNotification({
      message: getMutationErrorMessage(message, extensions),
      variant: "danger",
    });

    return true;
  }

  function handleTrpcClientError(
    error: unknown,
    tags?: Record<string, string>,
  ) {
    if (!(error instanceof TRPCClientError)) {
      return false;
    }

    const message = error.message ?? "Unexpected error occurred";

    openNotification({
      message,
      variant: "danger",
    });

    Sentry.captureException(error, { tags });

    return true;
  }

  function handleJsError(error: Error, tags?: Record<string, string>) {
    Sentry.captureException(error, { tags });

    return true;
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  function handleError(error: any, tags?: Record<string, string>) {
    if (handleFirebaseAuthError(error, tags)) return;
    if (handleGraphqlMutationError(error, tags)) return;
    if (handleTrpcClientError(error, tags)) return;

    handleJsError(error, tags);
  }

  return {
    handleFirebaseAuthError,
    handleGraphqlMutationError,
    handleTrpcClientError,
    handleError,
  };
}
