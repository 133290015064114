import { ApolloProvider, gql } from "@apollo/client";
import * as Sentry from "@sentry/nextjs";
import { buildClientSchema, getIntrospectionQuery } from "graphql";
import { TypeMap } from "graphql/type/schema";
import {
  createContext,
  FC,
  ReactNode,
  useContext,
  useEffect,
  useState,
} from "react";

import { useApollo } from "@/apollo/useApollo";
import { LoadingScreen } from "@/components/common/LoadingScreen";
import { useProject } from "@/contexts";
import { getPublicConfig } from "@/helpers/getPublicConfig";
import {
  generateDocuments,
  MutationWithInputObjectMap,
  QueryWithInputObjectMap,
} from "@/helpers/graphqlDocgen";

const { env } = getPublicConfig();

type GqlDocumentsContextType = {
  initialized: boolean;
  queries: Record<string, QueryWithInputObjectMap>;
  mutations: Record<string, MutationWithInputObjectMap>;
  entities: { name: string }[];
  typeMap: TypeMap;
};

const initialState: GqlDocumentsContextType = {
  initialized: false,
  queries: {},
  mutations: {},
  entities: [],
  typeMap: {},
};

const GqlDocumentsContext =
  createContext<GqlDocumentsContextType>(initialState);

export const GqlDocumentsProvider: FC<{ children: ReactNode }> = ({
  children,
}) => {
  const { project } = useProject();
  const client = useApollo(
    !project?.denyIntrospection
      ? project?.graphqlEndpoint[env.vercelEnv]
      : null,
  );

  const includeIntrospection =
    !project?.denyIntrospection && !project?.disableDynamicCollections;

  const [{ queries, mutations, entities, initialized, typeMap }, setDocuments] =
    useState<GqlDocumentsContextType>(initialState);

  useEffect(() => {
    async function getSchema() {
      Sentry.setTag("project", project?.slug);
      if (client && project && includeIntrospection) {
        setDocuments(initialState);
        try {
          const { data } = await client.query({
            query: gql(getIntrospectionQuery()),
            variables: { skip: project?.disableDynamicCollections },
          });
          const schema = buildClientSchema(data);

          const docs = generateDocuments(schema);
          setDocuments({ ...docs, initialized: true });
        } catch (e) {
          Sentry.withScope((scope) => {
            scope.setTag("action", "GET_SCHEMA");
            Sentry.captureException(e);
          });
        }
      }
    }

    getSchema();
  }, [client, project, includeIntrospection]);

  if (!project || !includeIntrospection) {
    return <>{children}</>;
  }

  if (!initialized || !client) {
    return <LoadingScreen text="Loading..." />;
  }

  return (
    <GqlDocumentsContext.Provider
      value={{
        queries,
        mutations,
        entities,
        typeMap,
        initialized,
      }}
    >
      <ApolloProvider client={client}>{children}</ApolloProvider>
    </GqlDocumentsContext.Provider>
  );
};

export const useGqlDocumentsContext = () => useContext(GqlDocumentsContext);
