import { Badge } from "@/components/tailwind/common/Badge";
import { useReportCount } from "@/features/princess/report/hooks/useReportCount";

export const ProcessingReportNotification = () => {
  const { activeReportCount = 0 } = useReportCount();

  if (activeReportCount === 0) return null;

  return <Badge name={activeReportCount.toString()} variant="inactive" />;
};
