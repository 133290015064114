import { NavigationItemType } from "@/features/common/components/navigation/types";

type CloudinaryConfig = {
  cloudName: string;
  uploadPreset: string;
  publicFolder?: string;
};

type EnvSettings = {
  production: string;
  preview: string;
  local: string;
};

export enum ProjectSlug {
  Princess = "princess",
}

type TypeSettings = {
  displayField: string;
};

export type ProjectSettings = {
  name: string;
  description?: string;
  slug: ProjectSlug;
  graphqlEndpoint: EnvSettings;
  webEndpoint?: EnvSettings;
  cmsBasePathRegex?: EnvSettings;
  allowEmailRegex?: EnvSettings;
  types?: Record<string, TypeSettings>;
  filters?: Record<string, string[]>;
  cloudinaryConfig?: CloudinaryConfig;
  defaultQueries?: Record<string, Record<string, string>>;
  disabledEntityCreations?: string[];
  reactPageColorPalette?: { title: string; colors: string[] }[];
  headerAlias?: Record<string, Record<string, string>>;
  simplifiedEnum?: Record<string, string[]>;
  denyIntrospection?: boolean;
  sidebarCollections?: NavigationItemType[];
  avatarUrl?: string;
  iconUrl?: string;
  disableDynamicCollections?: boolean;
};
