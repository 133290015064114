import { QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import App, { AppContext, AppProps } from "next/app";
import Head from "next/head";
import { FC, ReactNode, useEffect } from "react";
import { Toaster } from "react-hot-toast";

import { QueryParamProvider } from "@/components/common/QueryParamProvider";
import { NextPageWithLayout } from "@/components/layout";
import {
  AuthProvider,
  GqlDocumentsProvider,
  ProjectProvider,
} from "@/contexts";
import { NotificationProvider } from "@/contexts/notification";
import { getPublicConfig } from "@/helpers/getPublicConfig";
import { queryClient } from "@/services/queryClient";
import { projects } from "@/settings";
import { ProjectSettings } from "@/settings/types";
import { trpc } from "@/util/trpc";

import "@/services/firebase/firebase";
import "@/styles/unreset.css";
import "@/styles/react-image-zoom.css";
import "tailwindcss/tailwind.css";
import "../styles/global.css";

const Noop: FC<{ children: ReactNode }> = ({ children }) => <>{children}</>;

function MyApp({
  Component,
  pageProps,
  project,
}: AppProps & { project?: ProjectSettings }) {
  useEffect(() => {
    // Remove the server-side injected CSS.
    const jssStyles = document.querySelector("#jss-server-side");
    if (jssStyles) {
      jssStyles.parentElement?.removeChild(jssStyles);
    }
  }, []);

  const Layout = (Component as NextPageWithLayout).layout || Noop;

  return (
    <>
      <Head>
        <meta
          name="viewport"
          content="minimum-scale=1, initial-scale=1, width=device-width, shrink-to-fit=no, user-scalable=no, viewport-fit=cover"
        />
      </Head>

      <QueryParamProvider>
        <NotificationProvider>
          <QueryClientProvider client={queryClient}>
            <AuthProvider>
              <ProjectProvider projectFromHost={project}>
                <GqlDocumentsProvider>
                  <Layout>
                    <Component {...pageProps} />
                    <ReactQueryDevtools initialIsOpen={false} />
                  </Layout>
                </GqlDocumentsProvider>
              </ProjectProvider>
            </AuthProvider>
          </QueryClientProvider>
          <Toaster />
        </NotificationProvider>
      </QueryParamProvider>
    </>
  );
}

MyApp.getInitialProps = async (appContext: AppContext) => {
  const { env } = getPublicConfig();

  const { ctx, Component } = appContext;

  const appProps = await App.getInitialProps(appContext);
  const hostname = ctx.req
    ? ctx?.req?.headers?.host
    : typeof window !== "undefined"
      ? window?.location.hostname
      : undefined;

  let pageProps = {};

  if (Component.getInitialProps) {
    pageProps = await Component.getInitialProps(ctx);
  }

  const project = projects.find((p) => {
    return (
      p?.cmsBasePathRegex?.[env.vercelEnv] &&
      hostname &&
      new RegExp(p.cmsBasePathRegex[env.vercelEnv]).test(hostname)
    );
  });

  return {
    ...appProps,
    pageProps,
    project,
  };
};

export default trpc.withTRPC(MyApp);
